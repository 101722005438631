.custom-navbar {
    background-color: white; 
  }
  
 .header-image
 {
  width:150px;
  height:30px;
 }
 @media(max-width:750px)
 {
  .header-image{
    width:130px;
    height:25px;
  }
 }  
 @media(max-width:325px)
 {
  .header-image{
    width:100px;
    height:20px;
  }
 }
 @media(max-width:273px)
 {
  .header-image{
    width:80px;
    height:18px;
  }
 }

.custom-navbar {
  background-color: white;
 
}

/* Logo styles */
.header-image {
  margin-right: 30px;
  transition: box-shadow 0.3s;
}

.fixed-nav-link {
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.285);
  cursor: pointer;
  font-size: 18px;
  font-weight: bold; 
  font-family: 'Gentium Plus', serif; 
  text-decoration: none; 
  transition: color 0.3s; 
}
/* Header.css */
.navbar-toggler {
  padding: 5px;
  font-size: 0.8rem;
}



.fixed-nav-link:hover {
  color: rgb(21, 84, 161); 
  text-decoration: underline ;
}

.fixed-nav-link.active {
  color: rgb(21, 84, 161);
  text-decoration: underline ; 
}
