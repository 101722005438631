/* Style the container */
.contact-section {
  padding: 10px;
  background-color: #f9f9f9;

width: 100%;
}

/* Style the form section */
.custom-form {
  background-color: #f9f9f9;
  padding: 10px;
  padding-top: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 30px; /* Add a border to the form */
 
}

/* Style the form inputs and labels */
.form-group {
  margin-bottom: 5px;
}

/* Style the submit button */
.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Style the image container */
.image-container {
  height: 400px ; /* Adjust the height as needed, subtracting form padding */
  overflow: hidden; 
  border: 2px solid;
  border-radius: 30px;
  width:500px;
  margin-left: 40px;
  /* Hide overflow content if the image is larger */
}
@media (max-width:769px)
{
  .image-container{
    display: none;
  }
}
.image-container img{
  height: 100%;
  width:100%;
}


/* Style the "Get in Touch" heading */
.form-title {
  color: #0056b3;
  font-size: 1rem;
  /* margin-bottom: 20px; */
  text-align: center;
}

.form-label
{
  font-size: 1rem;
  font-weight: 500;
}
.contact-heading
{
  color: #0056b3;
  font-family: 'Gentium Plus', serif;
  font-size: 1.6rem;
  font-weight: 600;
}

