.product-subsection:hover {
  transform: scale(1.1); /* Zoom effect on hover */
  transition: transform 0.3s ease;
}


.product-heading-entered {
  animation: slideIn 1s ease-in-out forwards;
}

.underline-entered {
  animation: expandWidth 1s ease-in-out forwards;
}

.product-subsection-enter-0 {
  animation: slideFromLeft 1s ease-in-out forwards;
}

.product-subsection-enter-1 {
  animation: slideFromBottom 1s ease-in-out forwards;
}

.product-subsection-enter-2 {
  animation: slideFromRight 1s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
  slideIn {
    animation-duration: 0.3s; /* Faster animation duration */
  }
}

@keyframes expandWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
  
}



  .product-section {
    margin-bottom: 30px;
   width: 99%;
   background-color: white;
   
  }
  
  .product-content {
    background: rgba(0, 0, 0, 0.7);
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    min-height: 100%; /* Ensure equal height for all subsections */
  }
  h3 {
    margin-top: 0;
    font-size: 1.2rem;
    /* margin: 10px 0; */
    /* color: rgb(186, 189, 192); */
    /* #e74c3c; */
    font-family: 'Playfair Display', serif;
    font-weight: 600;
  }
  .product-subsection {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-blend-mode: soft-light; */
    min-height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 20px;
    color: #fff;
    margin-bottom: 20px;
    transition: transform 0.3s ease; /* Add a transition for smoother animation */
  }

  .product-subsection:hover {
    transform: scale(1.1); /* Zoom in effect on hover */
    font-weight: bold; /* Make text bolder on hover */
  }
  .product-heading {
    /* margin:20px; */
    padding: 10px 20px; /* Add padding to the heading for better appearance */
    border-radius: 5px; /* Add border radius for rounded corners */
    background-color: white; /* Set text color to white or any other suitable color */
    font-family: 'Gentium Plus', serif;
  font-size: 1.6rem;
  font-weight: 600;
  }
  .product-section h2
  {
    color: rgb(21, 84, 161) !important;
 
  }
  .product-section
  {
 
    
    background-color:white;
  }
  .product-content p
  {
    font-size: 1rem;
    font-family: 'Rubik', sans-serif;
  }
  @media (max-width:841px)
  {  
    .product-content {
      background: rgba(0, 0, 0, 0.7);
      padding: 5px;
      margin: 5px;
    }
  }
  @media (max-width:768px)
  {
    .product-subsection {
      min-height: 200px;
    }

  }