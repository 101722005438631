/* About.css */

/* Default styles */

.about {
  display: flex;
  background-color: #f7f7f7;
  padding: 10px;
  overflow: hidden;
  padding-bottom: 60px;
}

.about-left {
  flex: 1;
  text-align: center;
  color: #3498db;
  /* margin: 10px; */
  transform: translateX(-100%);
  transition: transform 0.5s ease-in;
}

.about-right {
  flex: 1;
  transform: translateX(100%);
  padding-top: 0;
  /* padding-right:10px ; */
  padding-left:10px ;
  padding-bottom:40px ;
  transition: transform 0.5s ease-in;
}

.about h1 {
  font-size: 1.4rem;
  font-family: 'lora',sans-serif;
  font-style: oblique;
}

.about-left h2 {
  
  font-size: 2rem;
  margin: 8px 0;
  color: white;
  font-family: 'Rubik', sans-serif;
  /* #e74c3c; */
}
.about-right h2 {
  font-size: 1rem;
  /* margin: 10px 0; */
  color: rgb(21, 84, 161);
  /* #e74c3c; */
  font-family: 'Playfair Display', serif;
  font-weight: 600;
}
.about-right li
{
  color:gray;
  font-size: 0.86rem;
  font-family: 'Poppins', sans-serif;
}

.about p {
  font-size: 0.9rem;
  /* margin-bottom: 20px; */
  color:gray;
  font-family: 'Poppins', sans-serif;
}

.about-content {
  padding-left: 10px;
  padding-right: 10px;
}

/* .about-approach,
.about-vision {
  margin-top: 20px;
} */

/* Responsive styles */

@media (max-width: 768px) {

  .about h1 {
   
    padding-top: 20px;
  }

  .about h2 {
  
    padding-bottom:20px ;
  }
.about-content
{
  padding-top: 10px;
}

.about-approach h2
{
  padding-bottom: 5px;
}

  .about {
    flex-direction: column;
    /* padding: 20px; */
  }

  .about-left,
  .about-right {
    width: 100%;
  }


  .about-left {
    text-align: center;
  }
} 
/* Animation styles */
.about-left.animate-in {
  transform: translateX(0);
}

.about-right.animate-in {
  transform: translateX(0);
}

.about-heading {
  margin:20px;
  font-family: 'Gentium Plus', serif;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 5px;
text-transform:uppercase;}


.about-section .about-heading 
{
  color: rgb(21, 84, 161) !important;
 
}
.about-section
{
  background-color: #f7f7f7;
  width: 100%;
}
.mainheading
{
  color: white !important;
}
/* @media(max-width:391px)
{
  .about-section
  {
    padding-top: 20px;
  }
} */
/* @media(max-width:321px)
{
  .about-section
  {
    padding-top: 50px;
  }
} */