.solution-subsection:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.solution-heading-entered {
  animation: slideIn 1s ease-in-out forwards;
}

.underline-entered {
  animation: expandWidth 1s ease-in-out forwards;
}

.solution-subsection-enter-0 {
  animation: slideFromLeft 1s ease-in-out forwards;
}

.solution-subsection-enter-1 {
  animation: slideFromBottom 1s ease-in-out forwards;
}

.solution-subsection-enter-2 {
  animation: slideFromRight 1s ease-in-out forwards;
}

.solution-subsection-enter-3 {
  animation: slideFromTop 1s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes expandWidth {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(50px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromTop {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.solution-section {

  margin-bottom: 50px;
  padding-bottom: 50px;
  width: 100%;
 
  
 
}
.solution-main-section
{
 padding: 30px;
border: 2px solid rgb(21, 84, 161);
box-shadow: #6b2727;
border-radius: 30px;
}

.solution-subsection {
  background-color: #f7f7f7dc;
  border: 2px solid;
  border-radius: 30px;

  height: 480px;
  display: flex;
  flex-direction: column;

  padding: 20px;
  color: rgba(21, 84, 161, 0.66);
  transition: transform 0.3s ease;
  margin-bottom: 40px;

}

.solution-subsection-heading {
  text-align: center;

  color: rgb(21, 84, 161);
  font-weight: bold;


}

.solution-subsection-heading h3 {
  font-size: 1rem;
  font-family: 'Playfair Display', serif;
  font-weight: 600;
}

.solution-content p{
  font-size: 0.9rem;
 
  font-family: 'Poppins', sans-serif;
}

.solution-subsection-divider {
  height: 2px;
  background-color: rgb(21, 84, 161);
  margin: 10px 0;
}


.solution-content {
  text-align: left !important;
}

.solution-section h2 {
  color: rgb(21, 84, 161);
  font-family: 'Gentium Plus', serif;
  font-size: 1.6rem;
  font-weight: 600;

}

/* .solution-subsection:hover {
  transform: scale(1.1);
  font-weight: bold;
} */

.solution-heading {
  padding-top: 72px;
  border-radius: 5px;
}
@media(max-width:960px)
{
  .solution-subsection {
    height: 560px;
  }

}
@media(max-width:879px)
{
  .solution-subsection {
    height: 750px;
  }

}
@media(max-width:769px)
{
  .solution-subsection {
    height: 220px;
  }

}
@media(max-width:569px)
{
  .solution-subsection {
    height: 280px;
  }

}
@media(max-width:469px)
{
  .solution-subsection {
    height: 330px;
  }

}
@media(max-width:369px)
{
  .solution-subsection {
    height: 400px;
  }

}
@media(max-width:320px)
{
  .solution-subsection {
    height: 450px;
  }

}
@media(max-width:289px)
{
  .solution-subsection {
    height: 540px;
  }

}
@media(max-width:255px)
{
  .solution-subsection {
    height: 600px;
  }

}
@media(max-width:240px)
{
  .solution-subsection {
    height: 700px;
  }

}