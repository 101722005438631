.service-heading {
  color: rgb(21, 84, 161);
  font-family: 'Gentium Plus', serif;
  font-size: 1.6rem;
  font-weight: 600;
}
.carousel {
  position: relative; 
}
.services-section{
  padding-top: 15px;
  width: 100% !important;
  background-color: #f7f7f7 !important;

}
.carousel-control-prev,
.carousel-control-next {
  top: auto;
  bottom: 20px; 
  width: 30px;
  height: 30px; 
  font-size: 30px; 
  line-height: 1;

}
.carousel-item {
  background-size: 100% 100%;
  text-align: center;
min-height: 350px;

}

.my-carousel {

  background-size: cover;
  background-repeat: no-repeat;
  margin: auto  ; 
  
  padding: 30px;
min-height: 500px;
  width: 100%;

}



.carousel-item.active {
  opacity: 1; 
}


.carousel-content h3 {
  font-size: 1.4rem;
  color: #fcf7f7e1;
  margin-bottom: 30px;
  font-weight: bold;
  margin-top: 70px;
  font-family: 'Playfair Display', serif;
  font-weight: 600;
}

.carousel-content p {
  font-size: 1.3rem;
  color: white;
  margin-bottom: 40px;
}

.services-section hr {
 
  margin: auto;
}
.carousel-content{
  padding-left: 20px;
}


.details-column{
  margin-right: 40px;
}
   .slide-0 {
    background-image: url('../assets/services2.png');
  }
  
  .slide-1 {
    background-image: url('../assets/services3.png');
  }.slide-2 {
    background-image: url('../assets/services4.png');
  }
  
  .slide-3 {
    background-image: url('../assets/services6.png');
  }