
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    object-fit: cover; 
    z-index: 900; 
  }
  
  .hero-content {
    position: relative;
    z-index: 1; 
    text-align: center; 
    padding-top: 110px;
    color: #fff; 
    width: 90%; 
    justify-content: center;
    margin: auto;
    z-index: 1020;
    padding-bottom: 100px;

  ;
    
  }

  .typing-animation {
    overflow: hidden;
    white-space: nowrap;
    animation: typing 3s steps(30, end), blink-caret 0.5s step-end infinite;
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-right-color: transparent;
    }
    50% {
      border-right-color: #fff;
    }
  }
  
  .hero-content h1 {
    font-size: 1.95rem !important; 
    font-family: 'Playfair Display', sans-serif;
  }
  
  .hero-content p {
    font-size: 1.3rem !important; 
    font-family: 'Rubik', serif;
  }

@keyframes borderAnimation {
    0% {
      border-image-slice: 1;
      background-color: transparent;
      color: #fff;
    }
    50% {
      border-image-slice: 10;
      background-color: red;
      color: #fff;
    }
    100% {
      border-image-slice: 1;
      background-color: transparent;
      color: #fff;
    }
  }
  
  .custom-button {
    border: 2px solid transparent;
    border-radius: 50px;
    padding: 8px 15px;
    background-color: transparent;
    color: rgb(21, 84, 161);
    font-size: 0.8rem !important;
    font-family:'Rubik', sans-serif;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #fff;
  
  
    &:hover {
      background-color:  rgb(21, 84, 161); 
      color:white;
    }
  }



.hero-content h1{
  font-size: 2.5rem;
  text-align: center;
  position: relative;

}
.hero-content p{
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 10px;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

  
  
/* @media(max-width:768px)
{
  
  .custom-button {
    padding: 10px;
  }
 
  .hero-content
  {
    padding-top: 150px;
  }
  
  
} */
/* @media (max-width:680px)
{
  .video-background{
    height: 90%;
  }
 
 
 
} */
@media (max-width:600px)
{
  .video-background{
    height: 80%;
  }
 
  .hero-container1{
    margin-top: 20px;
    height: 250px;
  }
 
}

@media(max-width:520px)
{
  
  .video-background
  {
    height: 85%;
  }

}
@media(max-width:390px)
{
 
  .video-background
  {
    height: 95%;
  }
  .hero-content
  {
    padding-top: 110px;
  }
  .custom-button
  {
    font-size: 0.8rem;
  }
  .hero-content h1{
    
    font-size: 1.9rem;
  }
  .hero-content p{
    font-size: 1.15rem;
  }


}
@media(max-width:320px)
{
  .video-background
  {
    height: 100%;
  }
  .hero-content h1{
    font-size: 2rem !important;
  }
}
