/* CircularInfographic.css */

.circular-container {
    position: relative;
    width: 120px; 
    height: 220px; 
    margin: 50px auto;
  }
  
  .circular-service {
    position: absolute;
    width: 120px; 
    height: 120px; 
    text-align: center;
    transform-origin: 50% 100%;
    transition: transform 0.3s ease-in-out;
  }
  
  .circular-title {
    background-color: #3498db; 
    color: #ffffff; 
    padding: 10px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 14px;
  }
  
  .circular-explanation {
    padding: 10px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 1.2;
  }
  
  .circular-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e74c3c; 
    color: #ffffff; 
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
  }
  
  
  .circular-service:nth-child(1) { transform: rotate(-90deg); }
  .circular-service:nth-child(2) { transform: rotate(-45deg); }
  .circular-service:nth-child(3) { transform: rotate(0deg); }
  .circular-service:nth-child(4) { transform: rotate(45deg); }
  
  
  