/* Footer.css */
.footer {
    background-color: #efecec;
    color: #100e0e;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    transform: translateY(100%);
    transition: transform 1s ease-in-out;
}

.footer-content {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}



.menu {
    list-style: none;
    padding: 0;
    display: flex;
}

.menu li {
    margin-right: 20px;
}

.menu a {
    text-decoration: none;
    color: #0f0d0dc8;
    transition: color 0.3s, border-bottom 0.3s;
    cursor: pointer;
    font-size: 14px;
   
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; /* Adjust font family as desired */
    text-decoration: none; /* Remove underlines from links */
}

.menu a:hover {
    color: #3498db;
    border-bottom: 2px solid #3498db;
}

  .copyright {
    margin-top: 5px;
    font-size: small;
  }
/* Footer.css */

/* Existing styles here */

/* Responsive styles */
@media (max-width: 768px) {
    .footer-content {
      flex-direction: column; /* Stack content vertically on smaller screens */
      text-align: center; 
      /* Center-align content */
    }
  
    .menu {
      margin-top: 10px; /* Add space between menu and logo */
    }
  
    .menu li {
   /* Remove margin between menu items */
      margin: 5px; /* Add space below menu items */
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    .footer-content {
      flex-direction: column; /* Stack content vertically on smaller screens */
      text-align: center; 
      /* Center-align content */
    }
  
    .menu {
      margin-top: 10px;
      flex-direction: column; /* Add space between menu and logo */
    }
  
    .menu li {
      margin-right: 0; /* Remove margin between menu items */
      margin-bottom: 5px; /* Add space below menu items */
      text-align: center;
    }
  }
  .footer-animate {
    transform: translateY(100%); /* Initially, move footer content out of view */
    transition: transform 1s ease-in-out;
  }
  
  .footer-animate.show {
    transform: translateY(0%); /* Animate footer content into view */
  }    
 






